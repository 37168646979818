// Import React
import React from 'react';

export default function ImperialRussia() {
    return (
        <article className="content app-body">
        
            <h1 className="center-div">Tension Between Workers and Soldiers</h1>

            <div className="center-div">
                <img 
                    className='article-img' 
                    src="../images/factoryguard.jpg" 
                    alt='Workers and soliders standing before the Petrograd factory in Imperial Russia'
                />

                <figcaption>
                    Factory workers and soldiers forming a guard for the <a 
                        href="https://commons.wikimedia.org/wiki/File:Red_Guard_Vulkan_factory.jpg" 
                        rel='noreferrer' 
                        target='_blank'>Vulkan Factory
                    </a> in Petrograd.
                </figcaption>
            </div>

            <div className="center-div">
                <figcaption>
                    <a href="../index.html">Donovan Frazier</a> | June 6, 2022 | Updated Feb 25, 2023
                </figcaption>
            </div>

            <div className="center-div">
                <p>
                    The months after the February Revolution was a period of splintering change in the relationship between workers and soldiers 
                    in Petrograd. 
                    The revolution itself was a unifying force in which soldiers and workers generally allied in a single cause, 
                    to take down the Tsarist government. 
                    The months after the revolution led to splits between individual army regiments and individual factories. 
                    Each of these groups of workers and 
                    soldiers had differing relationships with one another ranging from praising their unity to violence in the streets. 
                    This period demonstrates 
                    a time where workers and soldiers had the freedom to interact directly with one another without an external force like the 
                    Okhrana, or secret 
                    police, filtering information. This means that the masses could show their own agency, not a political party, which resulted 
                    in a complex, 
                    diverse relationship between the masses that was changing rapidly as recorded by literate workers and soldiers themselves.
                </p>

                <p>
                    The relationship between the workers and soldiers before the revolution was generally marked with contention in the 
                    broadest view going back decades. 
                    There had been several rebellions by the peasantry and the new emerging working class against the government, each of 
                    these rebellions put down by 
                    soldiers loyal to the Tsarist government. One of the most well-known examples of this was the 1905 Revolution in which 
                    workers took to the streets 
                    and protested against the government. The result of this was that the soldiers who remained loyal to the government put 
                    down the rebellion 
                    violently such as in massacres such as Bloody Sunday. For the Tsarist government, soldiers were the tool to putting down 
                    worker and peasant rebellions.
                </p>

                <p>
                    During the February Revolution of 1917, women workers in particular began demonstrations against the tsarist government, 
                    some arguing that they had given 
                    up their sons, husbands, and food for the war. The government was stripping away everything they needed to sustain 
                    themselves. This wasn’t new as women 
                    had been saying things such as, “No bread, no meat, no sugar, no anything,” a peasant woman said, for well over a 
                    year before the revolution began. 
                    This sentiment towards the war for women was nothing new, but these unfulfilled demands exploded into revolution on 
                    International Women’s Day in 1917. 
                    A male metal worker recounter women in the alleys of factories shouting, “Come out! Stop work!’ Snowballs pelted the 
                    windows. We decided to join the 
                    demonstration,” on the first morning of the revolution. About 87,000 workers struck on February 23rd of the Orthodox 
                    calendar, demanding bread according 
                    to Chief of Section, Major General of the Okhrana. The sentiment was clear from the mass demonstrations that the workers 
                    were once again in opposition to 
                    the government.
                </p>

                <p>
                    The garrisoned soldiers in Petrograd were once again placed in a position to respond to the demonstrations but 
                    unlike earlier revolutions, the soldiers sided 
                    with the workers, not remaining loyal to the government. Workers and soldiers at the point of the February Revolution 
                    were in the same revolutionary faction 
                    which was a turning point in their relationship. One such demonstration of this was on February 26th when a Cossack 
                    officer was reportedly fired at by an 
                    inspector for not following orders and the Cossack officer returned fire and killed the inspector.
                </p>

                <p>
                    Support for the demonstrations turned violent as Cossacks and soldiers turned their weapons on the loyalists. 
                    Another such example was the soldiers from the 
                    Volynskii Regiment killing their officers and provided weapons to workers in Petrograd. 
                    The soldiers siding with and arming the workers meant that the 
                    government couldn’t hold onto power since the main method of the government putting down demonstrations, 
                    soldiers firing on the peasants and workers, was 
                    out of the picture. The masses formed a cooperative relationship that caused the collapse of the government.
                </p>

                <p>
                    In the immediate aftermath of the February Revolution, this largely cooperative relationship continued. 
                    Workers for example could be seen honoring fallen soldiers. 
                    One such worker was a metalworker named Demian Semyonov in the Smolensk Province who in March dedicated a poem to fallen soldiers. 
                    In it, he states that 
                    the soldiers had sacrificed their blood for the workers’ sacred freedom. The soldiers were said to have unlocked the prisons 
                    and threw the tyrant’s yoke and 
                    allowed the dawn of freedom to break. This honoring of the soldiers expresses that the relationship between workers and 
                    soldiers was symbiotic. For Semyonov, 
                    this relationship was necessary for the Tsarist government to be taken down as it is the soldiers who sacrificed their 
                    blood for the workers, thus soldiers had 
                    the means to achieve the revolution whereas workers couldn’t have alone. This view displays that the workers and soldiers 
                    needed one another during this 
                    revolutionary period.
                </p>

                <p>
                    The relationship between workers and soldiers wasn’t so symbiotic everywhere. In late March, tension between workers and 
                    soldiers at other factories was building 
                    as their relationship was being questioned in response to news from the press. The People’s Cause, a Petrograd Socialist 
                    Revolutionary newspaper, reported that 
                    workers from the Lebedev Plant were refusing to work. This was an armament factory producing equipment for the war effort. 
                    Hearing that the workers were refusing 
                    to work at this factory especially was concerning for some soldiers of the Izamailovskii Regiment National Guard Reserve 
                    Battalion who would send delegates to the 
                    factory to check on the workers. This moment demonstrates that the relationship between workers and soldiers wasn’t so 
                    idealistic as Semyonov portrays and that 
                    other groups of workers and other groups of soldiers didn’t trust each other as much.
                </p>

                <p>
                    The workers of the Lebedev Plant and the Izamailovskii Regiment each replied to The People’s Cause in letters after the delegates 
                    from the soldiers had met the 
                    workers. For the factory, their letter responded, “We Lebedev Plant workers indignantly protest the dreadful allegations 
                    against us. We protest the against 
                    malevolent people who want to sow enmity between comrade workers and soldiers.”  Despite the sudden rise of tension between 
                    the workers of this factory and the 
                    soldiers of the Izamailovskii Regiment, there was a call for unity first and foremost. The workers of the factory argue 
                    that the allegations against them, that 
                    they were refusing to work, was false. The allegations instead are said to appear to be an attempt of unnamed entities 
                    trying to break the relationship between 
                    workers and soldiers. These unnamed entities aren’t shown to be anyone or any organization in particular. This does show 
                    us that the unnamed entities were an 
                    external force. This is critical because the workers are pointing to the press that an outside organization doesn’t like 
                    this strong relationship between workers 
                    and soldiers, whoever that organization may be. This letter shows that even as the tension was rising, workers at this 
                    plant and soldiers of this regiment were 
                    calling for unity and argued for using their agency to maintain this relationship even as external forces are attempting 
                    to break it up.
                </p>

                <p>
                    For the soldiers of the Izamailovskii Regiment, they also replied to The People’s Cause in a letter after their delegates 
                    returned from their inspection. In their 
                    letter, they state: “It’s true that not everything is straightened out. It’s true that you will hear the cries, ‘there are 
                    no materials! There is no coal!” 
                    This displays that the work being halted was true and that work wasn’t always getting done. However, the blame was not 
                    pinned on the workers unlike what 
                    The People’s Cause was claiming. “The workers all want work, not to hinder work. Our delegates saw entire mountains of 
                    artillery shells, entire mountains of 
                    awesome weapons.” The workers are addressed as wanting to continue work and were on the same side as the soldiers, wanting 
                    to support the war effort by 
                    producing equipment whenever they can but simply lack the materials to be as efficient as they can be. The blame doesn’t 
                    rest with the workers, instead, 
                    it rested with a lack of raw materials. The importance is that the soldiers established that the workers were still 
                    trustworthy in spite of the allegations 
                    of the press. The soldiers are also arguing for unity with the workers before trusting external forces that may not have 
                    this relationship as a goal. 
                    For this instance, the report on workers was the source of turbulence between the workers and soldiers of these organizations.
                </p>

                <p>
                    Up to this point, the relationship between workers and soldiers had gone from contentious before the revolution under the 
                    Tsarist government to symbiotic 
                    during the revolution in a combined call for deposing the government. It’s important to note that the workers had originally 
                    began the revolution demanding 
                    food and an end to the war and after the revolution, the zeal of the revolution reinvigorated the fighting effort as the 
                    soldiers of the Izamailovskii 
                    Regiment and the workers of the Lebedev Plant together were in favor of the war. This shows that even as tension rose 
                    between workers and soldiers, they 
                    can still have the same goals in mind and that the goal can change with the circumstances around them. A major take away 
                    from these experiences so far were 
                    that the workers and soldiers were demonstrating their own agency as they were seeking out what their relationship should 
                    be for themselves and questioning 
                    the motives of external entities trying to influence this relationship.
                </p>

                <p>
                    In April, another instance of tension between workers and soldiers was building, this time indirectly. 
                    Prisons had inmates released by soldiers which contributed 
                    to rising crime in the months after the revolution. The result of this increased crime was the rise of worker militias to 
                    combat the crime. Workers were responding 
                    to the predicament some soldiers had created with “mob justice” which was worker militias defending their residences. 
                    The actions of the soldiers led to 
                    hostile conditions for workers that the workers delt with by forming Red Guards. This is an example of soldiers causing 
                    turbulence in their relationship with 
                    workers in the districts affected most from these prison releases though it’s important to note that some Red Guards as 
                    seen with the figure above had soldiers 
                    mixed into their guards as well which also shows the complexity of the situation.
                </p>

                <p>
                    This relationship between workers and soldiers descended further during the July Crisis. This was an incident in which workers 
                    spontaneously took to the streets 
                    on July 3rd to protest the war. Many of these workers were gunned down by soldiers loyal to the Provisional Government that 
                    were following orders to disperse 
                    the crowd violently. The Bolsheviks would claim, “Indecent shootings by counterrevolutionaries could not distract from the 
                    demonstration’s general character.” 
                    The Bolsheviks paint the soldiers loyal to the Provisional Government negatively as oppositional to the revolution. It’s 
                    important to note that the Bolsheviks 
                    played a part in the strife of the July Crisis, but these demonstrations were spontaneous. The actions taken between the workers 
                    and the soldiers was determined 
                    by the workers and the soldiers deciding to follow order, not an outside faction like a political party such as the Bolsheviks or 
                    Socialist Revolutionaries. 
                    This again shows that the workers and soldiers had their own agency during this time between the February and October revolutions. 
                    The moment of the July 
                    Crisis represented previous patterns in which workers would strike in the streets and soldiers respond to put down the revolt. 
                    In this respect, the relationship 
                    between the workers and soldiers in this moment was reminiscent of their relationship during the Tsarist government, 
                    the most disconnected the workers and 
                    soldiers could have been from one another.
                </p>

                <p>Even as these soldiers and workers held a violent relationship, other workers and soldiers the following day stood in 
                    solidarity with one another as seen at 
                    the gunpowder workers and First Reserve Infantry Regimental Committee. “We 5,000 male and female workers and soldiers 
                    at the Shlissel’burg gunpowder plant, 
                    having discussed the 3 July events in Petrograd, greet and support the comrade Petrograd soldiers and workers who have 
                    protested in the struggle against 
                    counter revolutionaries.” This show of support for the protests among soldiers and workers at this plant displays a strong 
                    relationship between these 
                    military units and factories even as the relationship deteriorated for other factories and other units. Another aspect to 
                    note is that the soldiers said 
                    to be at this factory during the crisis supported the demonstrations, meaning they were in opposition to the soldiers of 
                    the July Crisis. The soldiers of 
                    these two regiments disagreed with one another which further shows that the soldiers didn’t always agree with one another. 
                    This shows that regiments had 
                    differing levels of allegiance to workers.
                </p>

                <p>
                    This relationship between the workers and soldiers at the July Crisis was much more like the relationship they had before the 
                    February Revolution. This was 
                    a very different relationship from the tense but still united stance against external forces like with the Izamailovskii 
                    Regiment and the Lebedev Plant; a 
                    very different relationship from the symbiotic relationship between the Volynskii Regiment and workers like Demian Semyonov 
                    fighting together against the 
                    Petrograd Police; and a very different relationship from the Red Guard defending the streets from criminals the soldiers 
                    inadvertently released during the 
                    revolution. The relationship between the soldiers and workers in the July Crisis was exactly the type of relationship that 
                    sparked revolutionary desire in 
                    the masses. This relationship represented soldiers being used by an oppressive external force to hinder the desire of the masses. 
                    Even during this moment 
                    of strife, other soldiers and other workers as seen with the Shlissel’burg gunpowder plant stood together. 
                    The relationship’s biggest turn so far after the 
                    February Revolution was that the individual regiments and factories had splintered into various responses. 
                    There was no longer one large unifying view 
                    that appeared to have united the majority of workers and soldiers as seen with the revolution’s goal of deposing the 
                    Tsar and ending the war.
                </p>

                <p>
                    The relationship between the soldiers and workers who were at the July Crisis appeared to be a relapse back to the status quo 
                    before the revolution, 
                    but a major difference is the calls for unity from outside forces that complemented the calls for unity among the workers and 
                    soldiers themselves mentioned above. 
                    Only two days after the July Crisis, on July 5th, the Petrograd Socialist Revolutionary’s Committee called for the relationship 
                    between workers and soldiers 
                    to be repaired. “Enough demonstrations! Enough wild unrest! Enough senseless parading the streets! Enough spilling of fraternal 
                    blood! The revolution needs the 
                    disciplined and solid unity of all workers and soldiers in Petrograd.” Even during a moment of upheaval in the relationship 
                    between workers and soldiers, 
                    shown with the killings, the harshest the relationship can be, there was simultaneous calls for unity. This unity was to be 
                    enforced much like with how 
                    the Izamailovskii Regiment contacted the Lebedev Plant. “All district committees must establish contact with the factories and 
                    military units and keep 
                    uninterrupted watch,” with explicit instructions for these decrees to be read aloud to all workers and soldiers at all party 
                    and general assemblies. 
                    The goal of this instruction appears to be to maintain direct contact between workers and soldiers, so their alliance is 
                    maintained. This appears to be 
                    directly aimed at preventing soldiers and workers from firing on one another again because of some outside force directing 
                    them such as the Provisional 
                    Government. This appears to be a recognition of the symbiotic relationship that Demian Semyonov was pointing to, that the 
                    workers and soldiers need 
                    one another with actions to ensure the workers and soldiers continue to cooperate under the watch of the Socialist Revolutionary 
                    Party.
                </p>

                <p>
                    The Socialist Revolutionaries weren’t the only party attempting to rally the soldiers and workers together. The Bolsheviks as 
                    well were attempting to 
                    rally the workers and soldiers under their party within a month of the July Crisis. “Our party, the party of the revolutionary 
                    proletariat, is running 
                    as list no. 6 in the 20 August election to the Central City Duma. Every worker, peasant, and soldier must cast their vote for 
                    this list only.” The 
                    Bolsheviks were claiming to be the sole representatives of peasants, workers, and soldiers and addressed them as a single faction, 
                    implying that these 
                    classes must be united.
                </p>

                <p>The Socialist Revolutionaries and the Bolsheviks represent external forces recognizing the soldiers and workers as a 
                    single faction that is vital to the 
                    goals of the parties. The soldiers and workers together took down the Tsarist government with their own agency. 
                    External forces thought to be attempting to 
                    break the relationship between soldiers and workers as seen in The People’s Cause actually sparked a strengthening of the 
                    soldiers and workers of their own 
                    will. The political parties attempting to rally the workers and soldiers together most likely know that the worker-soldier 
                    unity is a source of power that 
                    will give the ruling party the authority to shape Russia.
                </p>

                <p>
                    The external parties sought to unite all workers and soldiers, under each of their factions so that they can have the power of 
                    the masses that took down the 
                    Tsarist government under their own party, but the reality of the situation was that workers and soldiers expressed their own 
                    agency to spontaneously take 
                    down the Tsarist government together. Soldiers of the Volynskii Regiment killed their officers of their own accord and joined 
                    the very beginning of the 
                    revolution during the calls to end the war. Workers like Demian Semyonov praised the soldiers for joining the revolution and 
                    emphasized how necessary 
                    they were for the revolution to take place. The Lebedev Plant workers and Izamailovskii Regiment expressed initial mistrust of 
                    each other but called 
                    for unity and to not listen to external forces. The soldiers of the July Crisis listened to the external force, the Provisional 
                    Government, and put down 
                    the worker demonstrations in a similar vain to the Tsars in years prior. This is all the complexity of the relationship the 
                    workers and soldiers held 
                    between one another that the parties were attempting to tame.
                </p>

                <p>
                    The workers and soldiers had their own agency, acted independently of the parties, and held various views such as how the 
                    Volynskii Regiment were opposed 
                    to the war while the Izamailovskii Regiment and Lebedev Plant were in favor of the war. Rallying all of the soldiers and 
                    all of the workers under the 
                    influence of an external force such as these parties was a seemingly impossible task. The workers and soldiers held diverse 
                    views and were united and 
                    opposed to one another simultaneously depending on the regiment and the factory or district in question. Most importantly, 
                    the workers and soldiers were 
                    the ones with power to take down the Tsarist government of their own agency without an external force and even warned against 
                    believing external forces 
                    trying to influence their relationship. The parties rallying the workers and soldiers went against how the revolution began. 
                    This complexity in Petrograd 
                    alone points to the vast complexity that may have been seen in the rest of Russia.
                </p>

                <p>
                    The relationship between workers and soldiers in Petrograd in the months after the February Revolution shifted from one of 
                    contention before the revolution, 
                    to a symbiotic relationship in the month of the revolution, to a much more nuanced relationship that was tense, violent, 
                    appreciative, hopeful, and very 
                    mixed depending on the exact regiment and the exact factory or district in question. The general direction of the 
                    relationship in short was that it began 
                    with a single overall goal of taking down the Tsarist regime and achieving long awaited volia and ended up divisive and 
                    splintered before the October Revolution.
                </p>

                <h3>Bibliography</h3>

                <p>
                    Bulla, Viktor. Red Guard Vulkan Factory. Photograph. Wikipedia Commons. October 1917. 
                    https://commons.wikimedia.org/wiki/File:Red_Guard_Vulkan_factory.jpg
                </p>

                <p>
                    Chekalov, N. “Two Resolutions on the Petrograd Demonstrations.” In Competing Voices From the Russian Revolution, 
                    edited by Michael C. Hickey, 252-53. Santa Barbara: Greenwood, 2011.
                </p> 

                <p>
                    “Documents of the Okhrana on the February Days,” Docs February Revolution. 33-39. 1918.
                </p>

                <p>
                    Gapon, Georgy. “1905.” In Witnesses to the Russian Revolution, edited by Roger Pethbridge, 28-31. 
                    Crows Nest: Allen & Unwin, 1967.
                </p>

                <p>
                    Hasegawa, Tsuyoshi. “Crime, Police, and Mob Justice in Petrograd During the Russian Revolutions of 1917.” 
                    In Crime and Punishment in the Russian Revolution: Mob Justice and Police in Petrograd, 46-71. 
                    Cambridge: Harvard University Press, 2004.
                </p>

                <p>
                    League of Unfortunate Peasant Women. “Peasant Women Petition the War Minister, May 1916.” 
                    In Competing Voices From the Russian Revolution, edited by Michael C. Hickey, 57. Santa Barbara: Greenwood, 2011.
                </p> 

                <p>
                    Petrograd Socialist Revolutionary Committee, “To all Petrograd Soldiers and Workers.” 
                    In Competing Voices From the Russian Revolution, edited by Michael C. Hickey, 253-54. Santa Barbara: Greenwood, 2011.
                </p>

                <p>
                    Pravda. “The Bolsheviks on the Petrograd Demonstrations.” In Competing Voices From the Russian Revolution, 
                    edited by Michael C. Hickey, 254-55. Santa Barbara: Greenwood, 2011.
                </p> 

                <p>
                    Semyonov, Demian. “To the Freedom Fighters.” In Voices of Revolution, edited by Mark D. Steinberg, 83. 
                    New Haven: Yale University Press, 2001.
                </p> 

                <p>
                    Soldier Demonstrations, February 1917. Photograph. Wikipedia Commons. February, 1917. 
                    https://commons.wikimedia.org/wiki/File:Soldiers_demonstration.February_1917.jpg
                </p>
                
                <p>
                    “The Bolsheviks on the Petrograd City Duma Elections,” Proletarii. In Competing Voices From the Russian Revolution, 
                    edited by Michael C. Hickey, 380-81. Santa Barbara: Greenwood, 2011.
                </p> 
                
                <p>
                    “Two Letters on Worker-Soldier Relations.” In Voices of Revolution, edited by Mark D. Steinberg, 130-32. 
                    New Haven: Yale University Press, 2001.
                </p> 
                
                <p>
                    Wade, Rex A. The Russian Revolution, 1917. Third Edition. Cambridge: Cambridge University Press, 2017.
                </p> 
                
                <p>
                    Vasiliev. “March Days.” In Witnesses to the Russian Revolution, edited by Roger Pethbridge, 96-139. 
                    Crows Nest: Allen & Unwin, 1967.
                </p>
                
            </div>

            <div className="center-div">
                <button>View Comments (This is just a test feature, not actual comments)</button>
            </div>

            <div class="center-div">
                <p>If you wish to return to the orignal portfolio page, feel free to use the links in the footer to return to that section of the website</p>
            </div>

        </article>    
    )
}